<template>
<div>
    <!-- 客户发送量限制  增加 弹框 -->
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" destroy-on-close :show-close="false" width="88%" :modal-append-to-body="false">
        <!-- 头 -->
        <el-row class="dialog_top">
            <span>{{cur_title}}</span>
            <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <el-row style="overflow: auto;padding-right:10px;" >
                <el-form  :model="form_data" :label-width="(((content_width-360)/2 > 80 ? (content_width-300)/2 : 80 )+'px')" style="margin-bottom: 12px;" >
                    <el-form-item label="客户">
                        <el-select v-model="form_data.cpid" filterable  remote reserve-keyword placeholder="请选择客户" style="width:260px" size="mini" :remote-method="getUserDataList"
                         :disabled="this.prop_item.prop_type == 'upd'"
                        >
                            <el-option
                                v-for="item in userDataList"
                                :key="item.cpid"
                                :label="item.cpid+'('+item.company_name+')'"
                                :value="item.cpid">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="日期类型">
                        <el-select v-model="form_data.date_type" size="mini" style="width:260px" :disabled="this.prop_item.prop_type == 'upd'">
                            <el-option label="每日" :value="0"></el-option>
                            <el-option label="每月" :value="1"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="签名类型">
                        <el-select v-model="form_data.sign_type" size="mini" style="width:260px" :disabled="this.prop_item.prop_type == 'upd'">
                            <el-option label="不限制签名" :value="0"></el-option>
                            <el-option label="限制签名" :value="1"></el-option>
                        </el-select>
                    </el-form-item>
                   
                    <el-form-item label="签名" v-show="form_data.sign_type == 1" >
                        <el-input type="input" v-model="form_data.signword" placeholder="请输入签名" size="mini" style="width:260px" :disabled="this.prop_item.prop_type == 'upd'"></el-input>
                    </el-form-item>

                    <el-form-item label="限制条数">
                        <el-input v-model="form_data.num" placeholder="请输入整数" size="mini" style="width:260px" oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="onSubmit()" size="mini">保存</el-button>
                    </el-form-item>
                </el-form>

            </el-row>

        </el-row>


    </el-dialog>
</div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';

//import axios from "axios";
export default {
    props:{
        prop_change_i:0,
        prop_item:{},//公司对象
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            //初始化设置
            this.initSetUp();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 
            }

            if(this.prop_item.prop_type === "upd"){//修改
                this.cur_title = "修改客户发送量限制";
                setTimeout(() => {
                    //获取数据
                    this.getData();

                }, 1);
            }else{
                this.cur_title = "增加客户发送量限制";
                setTimeout(() => {
                   
                }, 1);
            }
        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见
            cur_title:"增加客户发送量限制",

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            userDataList:[],//客户列表数据-请求接口获取


            form_data:{
                cpid:"",//客户账号
                date_type:0,//日期类型：0：每日；1：每月
                sign_type:0,//签名限制类型：0：不限制签名 1：限制签名
                signword:"",//签名
                control_type:0,//限制类型：0：最大提交量 1：最大成功量
                num:1,//限制条数
            }
           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        //初始化设置
        this.initSetUp();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            
            
        },
         //数据清除
        dataClear(){
            this.form_data = this.$options.data().form_data;//单个恢复初始化
        },

        //查询
        getData(){
            this.form_data.cpid = this.prop_item.cpid;
            this.form_data.date_type = this.prop_item.date_type;////日期类型：0：每日；1：每月
            this.form_data.sign_type = this.prop_item.sign_type;//签名限制类型：0：不限制签名 1：限制签名
            this.form_data.signword = this.prop_item.signword;//签名
            this.form_data.control_type = this.prop_item.control_type;//限制类型：0：最大提交量 1：最大成功量
            this.form_data.num = this.prop_item.num;//限制条数

        },

        //提交
        onSubmit(){
            console.log("提交");

            if(API.isEmtry(this.form_data.cpid)){
                this.$message.error("请选择cpid");
                return;
            }
            if(this.form_data.sign_type == 1){
                if(API.isEmtry(this.form_data.signword)){
                    this.$message.error("请填写签名");
                    return;
                }
            }else{
                this.form_data.signword = "";
            }
            if(API.isEmtry(this.form_data.num)){
                this.$message.error("请填写限制条数");
                return;
            }


            var submit_obj = {};
            if(this.prop_item.prop_type === "upd"){//修改
                submit_obj.param = "upd_user_control";
                submit_obj.id = this.prop_item.id;
            }else{
                submit_obj.param = "add_user_control";//增加
            }
            submit_obj.cpid = this.form_data.cpid;//客户账号
            submit_obj.date_type = this.form_data.date_type;//日期类型：0：每日；1：每月
            submit_obj.sign_type = this.form_data.sign_type;//签名限制类型：0：不限制签名 1：限制签名
            submit_obj.signword = this.form_data.signword;//签名
            submit_obj.control_type = this.form_data.control_type;//限制类型：0：最大提交量 1：最大成功量
            submit_obj.num = this.form_data.num;//限制条数
            
            API.SystemControlServlet(submit_obj).then((res) => {
                if (res.recode === 0) {
                    res.text = "信息保存成功";

                    this.$alert(res.text).then(() => {
                        //关闭
                        this.back();
                        //回调
                        this.$emit("result",'');
                    }).catch(() => {
                        console.log("点击关闭按钮了");
                    });
                }
            });
        },

        //获取客户列表数据-请求接口获取
        getUserDataList(query){
            //请求接口
            API.UserServlet({
                param: "listAISelect",
                company:"",//公司名称
                son_cpid_show:"0",//是否展示子账号 0：不展示 1:展示
                searchID:query,//输入框填写的查询条件
                loading_onoff:false
            }).then((res) => {
                if (res.recode === 0) {
                    this.userDataList = res.list;//客户列表数据--请求接口获取
                }
            });
        },

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },


    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

/*form表单 间隔*/
.el-form-item {
  margin-bottom: 10px;
}
</style>